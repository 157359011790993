import _definePage_default_0 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/access-control.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/customer/CustomerPortal.vue?definePage&vue'
import _definePage_default_3 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/email/index.vue?definePage&vue'
import _definePage_default_4 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_5 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/login.vue?definePage&vue'
import _definePage_default_6 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_7 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_8 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/pages/user-profile/[tab].vue?definePage&vue'
import _definePage_default_9 from '/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/register.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/apps',
    /* internal name: 'apps' */
    /* no component */
    children: [
      {
        path: 'academy',
        /* internal name: 'apps-academy' */
        /* no component */
        children: [
          {
            path: 'dashboard',
            name: 'apps-academy-dashboard',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/academy/dashboard.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'billing',
        /* internal name: 'apps-billing' */
        /* no component */
        children: [
          {
            path: ':id',
            name: 'apps-billing-id',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/[id].vue'),
            /* no children */
          },
          {
            path: 'billing',
            name: 'apps-billing-billing',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/billing.vue'),
            /* no children */
          },
          {
            path: 'estimateHeader',
            name: 'apps-billing-estimate-header',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/estimateHeader.vue'),
            /* no children */
          },
          {
            path: 'list',
            name: 'apps-billing-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/list.vue'),
            /* no children */
          },
          {
            path: 'MessageDeleteConfirmDialog',
            name: 'apps-billing-message-delete-confirm-dialog',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/MessageDeleteConfirmDialog.vue'),
            /* no children */
          },
          {
            path: 'messages',
            name: 'apps-billing-messages',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/messages.vue'),
            /* no children */
          },
          {
            path: 'overview',
            name: 'apps-billing-overview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/overview.vue'),
            /* no children */
          },
          {
            path: 'payment',
            name: 'apps-billing-payment',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/payment.vue'),
            /* no children */
          },
          {
            path: 'paymentMethodCard',
            name: 'apps-billing-payment-method-card',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/billing/paymentMethodCard.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'clients',
        /* internal name: 'apps-clients' */
        /* no component */
        children: [
          {
            path: 'client_view',
            /* internal name: 'apps-clients-client-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-clients-client-view-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/[id].vue'),
                /* no children */
              },
              {
                path: 'About',
                name: 'apps-clients-client-view-about',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/About.vue'),
                /* no children */
              },
              {
                path: 'ActivityFeed',
                name: 'apps-clients-client-view-activity-feed',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ActivityFeed.vue'),
                /* no children */
              },
              {
                path: 'AdditionalContact',
                name: 'apps-clients-client-view-additional-contact',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/AdditionalContact.vue'),
                /* no children */
              },
              {
                path: 'AdditionalNumber',
                name: 'apps-clients-client-view-additional-number',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/AdditionalNumber.vue'),
                /* no children */
              },
              {
                path: 'addServiceAddress',
                name: 'apps-clients-client-view-add-service-address',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/addServiceAddress.vue'),
                /* no children */
              },
              {
                path: 'clientAgreement',
                name: 'apps-clients-client-view-client-agreement',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientAgreement.vue'),
                /* no children */
              },
              {
                path: 'clientAgreementFormEdit',
                name: 'apps-clients-client-view-client-agreement-form-edit',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientAgreementFormEdit.vue'),
                /* no children */
              },
              {
                path: 'ClientAgreementProductItem',
                name: 'apps-clients-client-view-client-agreement-product-item',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientAgreementProductItem.vue'),
                /* no children */
              },
              {
                path: 'ClientAgreementServiceItem',
                name: 'apps-clients-client-view-client-agreement-service-item',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientAgreementServiceItem.vue'),
                /* no children */
              },
              {
                path: 'ClientContactNew',
                name: 'apps-clients-client-view-client-contact-new',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientContactNew.vue'),
                /* no children */
              },
              {
                path: 'clientContacts',
                name: 'apps-clients-client-view-client-contacts',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientContacts.vue'),
                /* no children */
              },
              {
                path: 'clientJobs',
                name: 'apps-clients-client-view-client-jobs',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientJobs.vue'),
                /* no children */
              },
              {
                path: 'ClientNew',
                name: 'apps-clients-client-view-client-new',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientNew.vue'),
                /* no children */
              },
              {
                path: 'clientProfile',
                name: 'apps-clients-client-view-client-profile',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientProfile.vue'),
                /* no children */
              },
              {
                path: 'clientProfileHeader',
                name: 'apps-clients-client-view-client-profile-header',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/clientProfileHeader.vue'),
                /* no children */
              },
              {
                path: 'ClientQuickbooksSection',
                name: 'apps-clients-client-view-client-quickbooks-section',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientQuickbooksSection.vue'),
                /* no children */
              },
              {
                path: 'ClientServiceAddress',
                name: 'apps-clients-client-view-client-service-address',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ClientServiceAddress.vue'),
                /* no children */
              },
              {
                path: 'EmailContent',
                name: 'apps-clients-client-view-email-content',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/EmailContent.vue'),
                /* no children */
              },
              {
                path: 'MessageCard',
                name: 'apps-clients-client-view-message-card',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/MessageCard.vue'),
                /* no children */
              },
              {
                path: 'messages',
                name: 'apps-clients-client-view-messages',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/messages.vue'),
                /* no children */
              },
              {
                path: 'NotesSection',
                name: 'apps-clients-client-view-notes-section',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/NotesSection.vue'),
                /* no children */
              },
              {
                path: 'PaymentAddress',
                name: 'apps-clients-client-view-payment-address',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/PaymentAddress.vue'),
                /* no children */
              },
              {
                path: 'PaymentAddressWithoutGateway',
                name: 'apps-clients-client-view-payment-address-without-gateway',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/PaymentAddressWithoutGateway.vue'),
                /* no children */
              },
              {
                path: 'ServiceAddressCard',
                name: 'apps-clients-client-view-service-address-card',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ServiceAddressCard.vue'),
                /* no children */
              },
              {
                path: 'ServiceAddressSidebar',
                name: 'apps-clients-client-view-service-address-sidebar',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ServiceAddressSidebar.vue'),
                /* no children */
              },
              {
                path: 'ServiceAddressView',
                name: 'apps-clients-client-view-service-address-view',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ServiceAddressView.vue'),
                /* no children */
              },
              {
                path: 'ServiceAdressComponent',
                name: 'apps-clients-client-view-service-adress-component',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/ServiceAdressComponent.vue'),
                /* no children */
              },
              {
                path: 'showClient',
                name: 'apps-clients-client-view-show-client',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/showClient.vue'),
                /* no children */
              },
              {
                path: 'TransactionList',
                name: 'apps-clients-client-view-transaction-list',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/client_view/TransactionList.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'ClientImportSection',
            name: 'apps-clients-client-import-section',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/ClientImportSection.vue'),
            /* no children */
          },
          {
            path: 'clients',
            name: 'apps-clients-clients',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/clients.vue'),
            /* no children */
          },
          {
            path: 'overview',
            name: 'apps-clients-overview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/clients/overview.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'company',
        /* internal name: 'apps-company' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-company',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'apps-company-id',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/[id].vue'),
            /* no children */
          },
          {
            path: 'CompanyForm',
            name: 'apps-company-company-form',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/CompanyForm.vue'),
            /* no children */
          },
          {
            path: 'CompanyList',
            name: 'apps-company-company-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/CompanyList.vue'),
            /* no children */
          },
          {
            path: 'CompanySetting',
            name: 'apps-company-company-setting',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/CompanySetting.vue'),
            /* no children */
          },
          {
            path: 'DeleteReference',
            name: 'apps-company-delete-reference',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/DeleteReference.vue'),
            /* no children */
          },
          {
            path: 'PaymentGateway',
            name: 'apps-company-payment-gateway',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/PaymentGateway.vue'),
            /* no children */
          },
          {
            path: 'TransactionsReportList',
            name: 'apps-company-transactions-report-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/TransactionsReportList.vue'),
            /* no children */
          },
          {
            path: 'TwilioIntegration',
            name: 'apps-company-twilio-integration',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/company/TwilioIntegration.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'contact',
        /* internal name: 'apps-contact' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-contact',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/contact/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'crews',
        /* internal name: 'apps-crews' */
        /* no component */
        children: [
          {
            path: 'confirmDelete',
            name: 'apps-crews-confirm-delete',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/confirmDelete.vue'),
            /* no children */
          },
          {
            path: 'crew',
            name: 'apps-crews-crew',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/crew.vue'),
            /* no children */
          },
          {
            path: 'crew_view',
            /* internal name: 'apps-crews-crew-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-crews-crew-view-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/crew_view/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'crewCalendar',
            /* internal name: 'apps-crews-crew-calendar' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-crews-crew-calendar-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/crewCalendar/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'dispatch',
            /* internal name: 'apps-crews-dispatch' */
            /* no component */
            children: [
              {
                path: 'completeDispatch',
                name: 'apps-crews-dispatch-complete-dispatch',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/dispatch/completeDispatch.vue'),
                /* no children */
              },
              {
                path: 'list',
                name: 'apps-crews-dispatch-list',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/dispatch/list.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'editCrew',
            name: 'apps-crews-edit-crew',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/editCrew.vue'),
            /* no children */
          },
          {
            path: 'NewCrew',
            name: 'apps-crews-new-crew',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/NewCrew.vue'),
            /* no children */
          },
          {
            path: 'schedule',
            name: 'apps-crews-schedule',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/crews/schedule.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'customer',
        /* internal name: 'apps-customer' */
        /* no component */
        children: [
          {
            path: 'CustomerPayment',
            name: 'apps-customer-customer-payment',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/customer/CustomerPayment.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'CustomerPortal',
            name: 'apps-customer-customer-portal',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/customer/CustomerPortal.vue'),
            /* no children */
          },
  _definePage_default_2
  )
        ],
      },
      {
        path: 'dispatch',
        /* internal name: 'apps-dispatch' */
        /* no component */
        children: [
          {
            path: 'calendar',
            name: 'apps-dispatch-calendar',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/calendar.vue'),
            /* no children */
          },
          {
            path: 'DateDispatch',
            name: 'apps-dispatch-date-dispatch',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/DateDispatch.vue'),
            /* no children */
          },
          {
            path: 'list',
            name: 'apps-dispatch-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/list.vue'),
            /* no children */
          },
          {
            path: 'Map',
            name: 'apps-dispatch-map',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/Map.vue'),
            /* no children */
          },
          {
            path: 'overview',
            name: 'apps-dispatch-overview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/overview.vue'),
            /* no children */
          },
          {
            path: 'scheduled',
            name: 'apps-dispatch-scheduled',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/dispatch/scheduled.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'email',
        /* internal name: 'apps-email' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'apps-email',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/email/index.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
          {
            path: 'MailTemplate',
            name: 'apps-email-mail-template',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/email/MailTemplate.vue'),
            /* no children */
          },
          {
            path: 'TemplateBody',
            name: 'apps-email-template-body',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/email/TemplateBody.vue'),
            /* no children */
          },
          {
            path: 'templateList',
            name: 'apps-email-template-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/email/templateList.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'estimate',
        /* internal name: 'apps-estimate' */
        /* no component */
        children: [
          {
            path: 'estimates',
            name: 'apps-estimate-estimates',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/estimate/estimates.vue'),
            /* no children */
          },
          {
            path: 'messages',
            name: 'apps-estimate-messages',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/estimate/messages.vue'),
            /* no children */
          },
          {
            path: 'overview',
            name: 'apps-estimate-overview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/estimate/overview.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'inventory',
        /* internal name: 'apps-inventory' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-inventory',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/inventory/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'invoice',
        /* internal name: 'apps-invoice' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'apps-invoice-add' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-add',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/invoice/add/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'apps-invoice-edit' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-edit-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/invoice/edit/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'list',
            /* internal name: 'apps-invoice-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-list',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/invoice/list/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'preview',
            /* internal name: 'apps-invoice-preview' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-preview-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/invoice/preview/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'jobs',
        /* internal name: 'apps-jobs' */
        /* no component */
        children: [
          {
            path: 'AddItem',
            name: 'apps-jobs-add-item',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/AddItem.vue'),
            /* no children */
          },
          {
            path: 'Dispatches',
            /* internal name: 'apps-jobs-dispatches' */
            /* no component */
            children: [
              {
                path: 'DeleteDispatchModal',
                name: 'apps-jobs-dispatches-delete-dispatch-modal',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DeleteDispatchModal.vue'),
                /* no children */
              },
              {
                path: 'DispatchCrewDisplay',
                name: 'apps-jobs-dispatches-dispatch-crew-display',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchCrewDisplay.vue'),
                /* no children */
              },
              {
                path: 'DispatchList',
                name: 'apps-jobs-dispatches-dispatch-list',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchList.vue'),
                /* no children */
              },
              {
                path: 'DispatchListItem',
                name: 'apps-jobs-dispatches-dispatch-list-item',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchListItem.vue'),
                /* no children */
              },
              {
                path: 'DispatchModal',
                name: 'apps-jobs-dispatches-dispatch-modal',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchModal.vue'),
                /* no children */
              },
              {
                path: 'DispatchProducts',
                name: 'apps-jobs-dispatches-dispatch-products',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchProducts.vue'),
                /* no children */
              },
              {
                path: 'DispatchServices',
                name: 'apps-jobs-dispatches-dispatch-services',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchServices.vue'),
                /* no children */
              },
              {
                path: 'DispatchTouchUp',
                name: 'apps-jobs-dispatches-dispatch-touch-up',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/DispatchTouchUp.vue'),
                /* no children */
              },
              {
                path: 'edit',
                /* internal name: 'apps-jobs-dispatches-edit' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-jobs-dispatches-edit-id',
                    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/edit/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'MapFilters',
                name: 'apps-jobs-dispatches-map-filters',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/MapFilters.vue'),
                /* no children */
              },
              {
                path: 'MapModal',
                name: 'apps-jobs-dispatches-map-modal',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/MapModal.vue'),
                /* no children */
              },
              {
                path: 'new',
                /* internal name: 'apps-jobs-dispatches-new' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-jobs-dispatches-new-id',
                    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/new/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'NewDispatchForm',
                name: 'apps-jobs-dispatches-new-dispatch-form',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/NewDispatchForm.vue'),
                /* no children */
              },
              {
                path: 'ScheduleCalendar',
                name: 'apps-jobs-dispatches-schedule-calendar',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/ScheduleCalendar.vue'),
                /* no children */
              },
              {
                path: 'ScheduleCrewSection',
                name: 'apps-jobs-dispatches-schedule-crew-section',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/ScheduleCrewSection.vue'),
                /* no children */
              },
              {
                path: 'ScheduleDispatch',
                name: 'apps-jobs-dispatches-schedule-dispatch',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/ScheduleDispatch.vue'),
                /* no children */
              },
              {
                path: 'ScheduleDispatchItems',
                name: 'apps-jobs-dispatches-schedule-dispatch-items',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/ScheduleDispatchItems.vue'),
                /* no children */
              },
              {
                path: 'ScheduleDispatchTimes',
                name: 'apps-jobs-dispatches-schedule-dispatch-times',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/ScheduleDispatchTimes.vue'),
                /* no children */
              },
              {
                path: 'TimePicker',
                name: 'apps-jobs-dispatches-time-picker',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/TimePicker.vue'),
                /* no children */
              },
              {
                path: 'UpdateCalendarEvents',
                name: 'apps-jobs-dispatches-update-calendar-events',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Dispatches/UpdateCalendarEvents.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'JobModal',
            name: 'apps-jobs-job-modal',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/JobModal.vue'),
            /* no children */
          },
          {
            path: 'ModalSendInvoice',
            name: 'apps-jobs-modal-send-invoice',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/ModalSendInvoice.vue'),
            /* no children */
          },
          {
            path: 'Preview',
            name: 'apps-jobs-preview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/Preview.vue'),
            /* no children */
          },
          {
            path: 'SignPreview',
            name: 'apps-jobs-sign-preview',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/SignPreview.vue'),
            /* no children */
          },
          {
            path: 'UI',
            /* internal name: 'apps-jobs-ui' */
            /* no component */
            children: [
              {
                path: 'EstimateFooter',
                name: 'apps-jobs-uiestimate-footer',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/UI/EstimateFooter.vue'),
                /* no children */
              },
              {
                path: 'LostConfirmModal',
                name: 'apps-jobs-uilost-confirm-modal',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/UI/LostConfirmModal.vue'),
                /* no children */
              },
              {
                path: 'ModalConfirmChange',
                name: 'apps-jobs-uimodal-confirm-change',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/UI/ModalConfirmChange.vue'),
                /* no children */
              },
              {
                path: 'StatusPill',
                name: 'apps-jobs-uistatus-pill',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/UI/StatusPill.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'view',
            /* internal name: 'apps-jobs-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-jobs-view-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/view/[id].vue'),
                /* no children */
              },
              {
                path: 'AgreementProductItem',
                name: 'apps-jobs-view-agreement-product-item',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/view/AgreementProductItem.vue'),
                /* no children */
              },
              {
                path: 'Agreements',
                name: 'apps-jobs-view-agreements',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/view/Agreements.vue'),
                /* no children */
              },
              {
                path: 'AgreementServiceItem',
                name: 'apps-jobs-view-agreement-service-item',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/view/AgreementServiceItem.vue'),
                /* no children */
              },
              {
                path: 'ProjectView',
                name: 'apps-jobs-view-project-view',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/jobs/view/ProjectView.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'logistics',
        /* internal name: 'apps-logistics' */
        /* no component */
        children: [
          {
            path: 'dashboard',
            name: 'apps-logistics-dashboard',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/logistics/dashboard.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'messaging',
        /* internal name: 'apps-messaging' */
        /* no component */
        children: [
          {
            path: 'messages',
            name: 'apps-messaging-messages',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/messaging/messages.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'notPresent',
        name: 'apps-not-present',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/notPresent.vue'),
        /* no children */
      },
      {
        path: 'permissions',
        /* internal name: 'apps-permissions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-permissions',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/permissions/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'profiles',
        /* internal name: 'apps-profiles' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-profiles',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            /* internal name: 'apps-profiles-edit' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-profiles-edit-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/edit/[id].vue'),
                /* no children */
              },
              {
                path: 'ImageCropper',
                name: 'apps-profiles-edit-image-cropper',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/edit/ImageCropper.vue'),
                /* no children */
              },
              {
                path: 'ImageUpload',
                name: 'apps-profiles-edit-image-upload',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/edit/ImageUpload.vue'),
                /* no children */
              },
              {
                path: 'ModalConfirmDelete',
                name: 'apps-profiles-edit-modal-confirm-delete',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/edit/ModalConfirmDelete.vue'),
                /* no children */
              },
              {
                path: 'ProfileEdit',
                name: 'apps-profiles-edit-profile-edit',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/edit/ProfileEdit.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'ProfileCards',
            name: 'apps-profiles-profile-cards',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/ProfileCards.vue'),
            /* no children */
          },
          {
            path: 'ProfileDataTable',
            name: 'apps-profiles-profile-data-table',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/ProfileDataTable.vue'),
            /* no children */
          },
          {
            path: 'ProfileList',
            name: 'apps-profiles-profile-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/ProfileList.vue'),
            /* no children */
          },
          {
            path: 'view',
            /* internal name: 'apps-profiles-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-profiles-view-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/view/[id].vue'),
                /* no children */
              },
              {
                path: 'ProfileViewUser',
                name: 'apps-profiles-view-profile-view-user',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/profiles/view/ProfileViewUser.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'psm',
        /* internal name: 'apps-psm' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-psm',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/index.vue'),
            /* no children */
          },
          {
            path: 'AddDiscount',
            name: 'apps-psm-add-discount',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/AddDiscount.vue'),
            /* no children */
          },
          {
            path: 'AddPackage',
            name: 'apps-psm-add-package',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/AddPackage.vue'),
            /* no children */
          },
          {
            path: 'AddProduct',
            name: 'apps-psm-add-product',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/AddProduct.vue'),
            /* no children */
          },
          {
            path: 'createService',
            name: 'apps-psm-create-service',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/createService.vue'),
            /* no children */
          },
          {
            path: 'discount',
            name: 'apps-psm-discount',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/discount.vue'),
            /* no children */
          },
          {
            path: 'packages',
            name: 'apps-psm-packages',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/packages.vue'),
            /* no children */
          },
          {
            path: 'products',
            name: 'apps-psm-products',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/products.vue'),
            /* no children */
          },
          {
            path: 'services',
            name: 'apps-psm-services',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/services.vue'),
            /* no children */
          },
          {
            path: 'visitCard',
            name: 'apps-psm-visit-card',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/visitCard.vue'),
            /* no children */
          },
          {
            path: 'visitSection',
            name: 'apps-psm-visit-section',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/psm/visitSection.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'quickbooks',
        /* internal name: 'apps-quickbooks' */
        /* no component */
        children: [
          {
            path: 'ModalQbAuth',
            name: 'apps-quickbooks-modal-qb-auth',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/quickbooks/ModalQbAuth.vue'),
            /* no children */
          },
          {
            path: 'ModalUnsynced',
            name: 'apps-quickbooks-modal-unsynced',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/quickbooks/ModalUnsynced.vue'),
            /* no children */
          },
          {
            path: 'QuickbooksDiscountSection',
            name: 'apps-quickbooks-quickbooks-discount-section',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/quickbooks/QuickbooksDiscountSection.vue'),
            /* no children */
          },
          {
            path: 'QuickbooksProductSection',
            name: 'apps-quickbooks-quickbooks-product-section',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/quickbooks/QuickbooksProductSection.vue'),
            /* no children */
          },
          {
            path: 'QuickbooksServiceSection',
            name: 'apps-quickbooks-quickbooks-service-section',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/quickbooks/QuickbooksServiceSection.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'reports',
        /* internal name: 'apps-reports' */
        /* no component */
        children: [
          {
            path: 'accountsReport',
            name: 'apps-reports-accounts-report',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/reports/accountsReport.vue'),
            /* no children */
          },
          {
            path: 'backlogReport',
            name: 'apps-reports-backlog-report',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/reports/backlogReport.vue'),
            /* no children */
          },
          {
            path: 'crewJobs',
            name: 'apps-reports-crew-jobs',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/reports/crewJobs.vue'),
            /* no children */
          },
          {
            path: 'crewReport',
            name: 'apps-reports-crew-report',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/reports/crewReport.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'roles',
        /* internal name: 'apps-roles' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-roles',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/roles/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'schedules',
        /* internal name: 'apps-schedules' */
        /* no component */
        children: [
          {
            path: 'calendar',
            /* internal name: 'apps-schedules-calendar' */
            /* no component */
            children: [
              {
                path: 'CalendarEventHandler',
                name: 'apps-schedules-calendar-calendar-event-handler',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/schedules/calendar/CalendarEventHandler.vue'),
                /* no children */
              },
              {
                path: 'EvenDeleteConfirmModal',
                name: 'apps-schedules-calendar-even-delete-confirm-modal',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/schedules/calendar/EvenDeleteConfirmModal.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'officeCalendar',
            name: 'apps-schedules-office-calendar',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/schedules/officeCalendar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'settings',
        /* internal name: 'apps-settings' */
        /* no component */
        children: [
          {
            path: 'Communication',
            name: 'apps-settings-communication',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/Communication.vue'),
            /* no children */
          },
          {
            path: 'CompanyBranding',
            name: 'apps-settings-company-branding',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/CompanyBranding.vue'),
            /* no children */
          },
          {
            path: 'Customization',
            name: 'apps-settings-customization',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/Customization.vue'),
            /* no children */
          },
          {
            path: 'Leads',
            name: 'apps-settings-leads',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/Leads.vue'),
            /* no children */
          },
          {
            path: 'Payments',
            name: 'apps-settings-payments',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/Payments.vue'),
            /* no children */
          },
          {
            path: 'PortalCustomizationSettings',
            name: 'apps-settings-portal-customization-settings',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/PortalCustomizationSettings.vue'),
            /* no children */
          },
          {
            path: 'QuickbookLogin',
            name: 'apps-settings-quickbook-login',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/QuickbookLogin.vue'),
            /* no children */
          },
          {
            path: 'ReferredBy',
            name: 'apps-settings-referred-by',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/ReferredBy.vue'),
            /* no children */
          },
          {
            path: 'Settings',
            name: 'apps-settings-settings',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/settings/Settings.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'sms',
        /* internal name: 'apps-sms' */
        /* no component */
        children: [
          {
            path: 'SmsTemplate',
            name: 'apps-sms-sms-template',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/sms/SmsTemplate.vue'),
            /* no children */
          },
          {
            path: 'SmsTemplateBody',
            name: 'apps-sms-sms-template-body',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/sms/SmsTemplateBody.vue'),
            /* no children */
          },
          {
            path: 'SmsTemplateList',
            name: 'apps-sms-sms-template-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/sms/SmsTemplateList.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'superadmin',
        /* internal name: 'apps-superadmin' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-superadmin',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/index.vue'),
            /* no children */
          },
          {
            path: 'dashboard',
            /* internal name: 'apps-superadmin-dashboard' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-superadmin-dashboard',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/index.vue'),
                /* no children */
              },
              {
                path: 'CardStatistics',
                name: 'apps-superadmin-dashboard-card-statistics',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/CardStatistics.vue'),
                /* no children */
              },
              {
                path: 'CompanyGraphStats',
                name: 'apps-superadmin-dashboard-company-graph-stats',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/CompanyGraphStats.vue'),
                /* no children */
              },
              {
                path: 'LeadsToday',
                name: 'apps-superadmin-dashboard-leads-today',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/LeadsToday.vue'),
                /* no children */
              },
              {
                path: 'SalesRepLeaders',
                name: 'apps-superadmin-dashboard-sales-rep-leaders',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/SalesRepLeaders.vue'),
                /* no children */
              },
              {
                path: 'TopReferralSource',
                name: 'apps-superadmin-dashboard-top-referral-source',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/dashboard/TopReferralSource.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'SuperAdminForm',
            name: 'apps-superadmin-super-admin-form',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/SuperAdminForm.vue'),
            /* no children */
          },
          {
            path: 'SuperAdminList',
            name: 'apps-superadmin-super-admin-list',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/superadmin/SuperAdminList.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'tasks',
        /* internal name: 'apps-tasks' */
        /* no component */
        children: [
          {
            path: 'allTasks',
            name: 'apps-tasks-all-tasks',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/allTasks.vue'),
            /* no children */
          },
          {
            path: 'DeleteTaskModal',
            name: 'apps-tasks-delete-task-modal',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/DeleteTaskModal.vue'),
            /* no children */
          },
          {
            path: 'myTasks',
            name: 'apps-tasks-my-tasks',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/myTasks.vue'),
            /* no children */
          },
          {
            path: 'othersTasks',
            name: 'apps-tasks-others-tasks',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/othersTasks.vue'),
            /* no children */
          },
          {
            path: 'showEventPopUp',
            name: 'apps-tasks-show-event-pop-up',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/showEventPopUp.vue'),
            /* no children */
          },
          {
            path: 'ShowTasks',
            name: 'apps-tasks-show-tasks',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/ShowTasks.vue'),
            /* no children */
          },
          {
            path: 'Task',
            name: 'apps-tasks-task',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/tasks/Task.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'user',
        /* internal name: 'apps-user' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-user-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-user-list',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/user/list/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'view',
            /* internal name: 'apps-user-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-user-view-id',
                component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/apps/user/view/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/apps/email/:filter',
    name: 'apps-email-filter',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/apps/email/:label',
    name: 'apps-email-label',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/components',
    /* internal name: 'components' */
    /* no component */
    children: [
      {
        path: 'alert',
        name: 'components-alert',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/alert.vue'),
        /* no children */
      },
      {
        path: 'avatar',
        name: 'components-avatar',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/avatar.vue'),
        /* no children */
      },
      {
        path: 'badge',
        name: 'components-badge',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/badge.vue'),
        /* no children */
      },
      {
        path: 'button',
        name: 'components-button',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/button.vue'),
        /* no children */
      },
      {
        path: 'chip',
        name: 'components-chip',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/chip.vue'),
        /* no children */
      },
      {
        path: 'DeleteConfirmDialog',
        name: 'components-delete-confirm-dialog',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/DeleteConfirmDialog.vue'),
        /* no children */
      },
      {
        path: 'dialog',
        name: 'components-dialog',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/dialog.vue'),
        /* no children */
      },
      {
        path: 'expansion-panel',
        name: 'components-expansion-panel',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/expansion-panel.vue'),
        /* no children */
      },
      {
        path: 'list',
        name: 'components-list',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/list.vue'),
        /* no children */
      },
      {
        path: 'menu',
        name: 'components-menu',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/menu.vue'),
        /* no children */
      },
      {
        path: 'pagination',
        name: 'components-pagination',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/pagination.vue'),
        /* no children */
      },
      {
        path: 'progress-circular',
        name: 'components-progress-circular',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/progress-circular.vue'),
        /* no children */
      },
      {
        path: 'progress-linear',
        name: 'components-progress-linear',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/progress-linear.vue'),
        /* no children */
      },
      {
        path: 'snackbar',
        name: 'components-snackbar',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/snackbar.vue'),
        /* no children */
      },
      {
        path: 'tabs',
        name: 'components-tabs',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/tabs.vue'),
        /* no children */
      },
      {
        path: 'timeline',
        name: 'components-timeline',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/timeline.vue'),
        /* no children */
      },
      {
        path: 'tooltip',
        name: 'components-tooltip',
        component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/components/tooltip.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  {
    path: '/loader',
    name: 'loader',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/loader.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/pages',
    /* internal name: 'pages' */
    /* no component */
    children: [
      {
        path: 'account-settings',
        /* internal name: 'pages-account-settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-account-settings-tab',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/pages/account-settings/[tab].vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'user-profile',
        /* internal name: 'pages-user-profile' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-user-profile-tab',
            component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/pages/user-profile/[tab].vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/home/devs/mantx/Mantx-new-frontend-multi-tenant/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_9
  )
]
